import React from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./index.css";
import App from "./app/layout/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { configureStore, history } from "./app/store/configureStore";
import ScrollToTop from "./app/layout/ScrollToTop";
import { ConnectedRouter } from "connected-react-router";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import firebase from "./app/config/firebase";
import ErrorBoundary from "./app/layout/ErrorBoundary";

//Enable Sentry if not localhost
const hostname = window && window.location && window.location.hostname;
if (hostname !== "localhost") {
    Sentry.init({ dsn: "https://bbab5965bead4300b6891257f0cbf86d@o384738.ingest.sentry.io/5648545", release: "5.3.3" });
}

const store = configureStore();

const rrfConfig = {
    userProfile: "users",
    attachAuthIsReady: true,
    useFirestoreForProfile: true,
    updateProfileOnLogin: true,
};

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
};

const rootEl = document.getElementById("root");

function render() {
    ReactDOM.render(
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <ConnectedRouter history={history}>
                    <ScrollToTop />
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </ConnectedRouter>
            </ReactReduxFirebaseProvider>
        </Provider>,
        rootEl
    );
}

if (module.hot) {
    module.hot.accept("./app/layout/App", function () {
        setTimeout(render);
    });
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
